<template>
  <div class="page">
    <div class="title">员工信息</div>

    <el-divider></el-divider>
    <!--  -->
    <el-form
      ref="form"
      label-position="top"
      :model="form"
      label-width="80px"
      :rules="rules"
    >
      <el-form-item label="员工基本信息" class="bold_form">
        <el-row>
          <el-col :span="8"
            ><el-form-item label="合同编号" class="normal_form">
              <el-input v-model="form.contract_sn"></el-input> </el-form-item
          ></el-col>
          <el-col :span="8"
            ><el-form-item
              label="所在单位"
              class="normal_form"
              prop="department_id"
            >
              <!-- <el-input v-model="form.department_id"></el-input>  -->
              <el-cascader
                placeholder="请选择所在单位"
                :options="department"
                filterable
                v-model="form.department_id"
                clearable
              ></el-cascader> </el-form-item
          ></el-col>
          <el-col :span="8"
            ><el-form-item label="所在项目" class="normal_form" prop="duty_id">
              <el-cascader
                placeholder="请选择所在项目"
                :options="projectList"
                filterable
                v-model="form.duty_id"
                clearable
              ></el-cascader> </el-form-item
          ></el-col>
        </el-row>

        <el-row>
          <el-col :span="8"
            ><el-form-item label="用工类别" class="normal_form">
              <el-radio-group v-model="form.is_social_security">
                <el-radio :label="1">A</el-radio>
                <el-radio :label="0">B</el-radio>
              </el-radio-group>
            </el-form-item></el-col
          >

          <el-col :span="8"
            ><el-form-item
              label="岗位层级"
              class="normal_form"
              prop="post_level"
            >
              <el-input v-model="form.post_level"></el-input> </el-form-item
          ></el-col>
          <el-col :span="8"
            ><el-form-item
              label="岗位名称"
              class="normal_form"
              prop="station_id"
            >
              <el-cascader
                placeholder="请选择岗位名称"
                :options="stationList"
                filterable
                v-model="form.station_id"
                clearable
              ></el-cascader> </el-form-item
          ></el-col>
        </el-row>

        <el-row>
          <el-col :span="8"
            ><el-form-item label="员工编号" class="normal_form">
              <el-input
                v-model="form.job_number"
                placeholder="请输入岗位层级"
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="8"
            ><el-form-item label="姓名" class="normal_form" prop="real_name">
              <el-input
                v-model="form.real_name"
                placeholder="请输入姓名"
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="8"
            ><el-form-item label="性别" class="normal_form">
              <el-radio-group v-model="form.sex">
                <el-radio label="男">男</el-radio>
                <el-radio label="女">女</el-radio>
              </el-radio-group>
            </el-form-item></el-col
          >
        </el-row>

        <el-row>
          <el-col :span="8"
            ><el-form-item label="民族" class="normal_form" prop="nation">
              <el-input
                v-model="form.nation"
                placeholder="请输入民族"
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="8"
            ><el-form-item label="出生年月" class="normal_form" prop="birthday">
              <el-date-picker
                v-model="form.birthday"
                type="date"
                placeholder="请选择出生年月"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker> </el-form-item
          ></el-col>
          <el-col :span="8"
            ><el-form-item label="年龄" class="normal_form" prop="age">
              <el-input
                v-model="form.age"
                placeholder="请选择年龄"
              ></el-input> </el-form-item
          ></el-col>
        </el-row>

        <el-row>
          <el-col :span="8"
            ><el-form-item
              label="身份证号码"
              class="normal_form"
              prop="cert_number"
            >
              <el-input
                v-model="form.cert_number"
                placeholder="请输入身份证号码"
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="8"
            ><el-form-item
              label="身份证有效止期"
              class="normal_form"
              prop="certifity_expired"
            >
              <el-date-picker
                v-model="form.certifity_expired"
                type="date"
                placeholder="请选择身份证有效止期"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker> </el-form-item
          ></el-col>
          <el-col :span="8"
            ><el-form-item label="政治面貌" class="normal_form" prop="face">
              <el-input
                v-model="form.face"
                placeholder="请输入政治面貌"
              ></el-input> </el-form-item
          ></el-col>
        </el-row>

        <el-row>
          <el-col :span="8"
            ><el-form-item label="婚否" class="normal_form">
              <el-radio-group v-model="form.is_marry">
                <el-radio :label="1">已婚</el-radio>
                <el-radio :label="0">未婚</el-radio>
              </el-radio-group>
            </el-form-item></el-col
          >
          <el-col :span="8"
            ><el-form-item label="工种" class="normal_form" prop="work_type">
              <el-input
                v-model="form.work_type"
                placeholder="请输入工种"
              ></el-input> </el-form-item
          ></el-col>
        </el-row>
      </el-form-item>
      <el-form-item label="学历情况" class="bold_form">
        <el-row>
          <el-col :span="8"
            ><el-form-item label="文化程度" class="normal_form" prop="edu">
              <el-input
                v-model="form.edu"
                placeholder="请输入文化程度"
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="8"
            ><el-form-item
              label="毕业时间"
              class="normal_form"
              prop="graduation_time"
            >
              <el-date-picker
                v-model="form.graduation_time"
                type="date"
                placeholder="请选择毕业时间"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker> </el-form-item
          ></el-col>
          <el-col :span="8"
            ><el-form-item
              label="毕业学校"
              class="normal_form"
              prop="graduation_college"
            >
              <el-input
                v-model="form.graduation_college"
                placeholder="请输入毕业学校"
              ></el-input> </el-form-item
          ></el-col>
        </el-row>
        <el-row>
          <el-col :span="8"
            ><el-form-item
              label="毕业专业"
              class="normal_form"
              prop="graduation_major"
            >
              <el-input
                v-model="form.graduation_major"
                placeholder="请输入毕业专业"
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="8"
            ><el-form-item
              label="学历证书编号"
              class="normal_form"
              prop="edu_prove"
            >
              <el-input
                v-model="form.edu_prove"
                placeholder="请输入学历证书编号"
              ></el-input> </el-form-item
          ></el-col>
        </el-row>
      </el-form-item>
      <el-form-item label="个人情况" class="bold_form">
        <el-row>
          <el-col :span="8"
            ><el-form-item
              label="户口所在地省市区"
              class="normal_form"
              prop="dataModel"
            >
              <el-cascader
                v-model="form.dataModel"
                placeholder="请选择户口所在地省市区"
                :options="cityList"
              >
              </el-cascader> </el-form-item
          ></el-col>
          <el-col :span="8">
            <el-form-item
              label="户口性质"
              class="normal_form"
              prop="residence_type"
            >
              <el-select
                v-model="form.residence_type"
                placeholder="请选择户口性质"
              >
                <el-option
                  v-for="item in residence_type_options"
                  :key="item.id"
                  :label="item.name"
                  :value="item.name"
                >
                </el-option>
              </el-select> </el-form-item
          ></el-col>
          <el-col :span="8"
            ><el-form-item label="户口地址" class="normal_form" prop="address">
              <el-input
                v-model="form.address"
                placeholder="请选择户口地址"
              ></el-input> </el-form-item
          ></el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item
              label="现家庭所在地省市区"
              class="normal_form"
              prop="currentData"
            >
              <el-cascader
                v-if="areaData"
                v-model="form.currentData"
                placeholder="请选择现家庭所在地省市区"
                :options="cityList"
              >
              </el-cascader> </el-form-item
          ></el-col>
          <el-col :span="8"
            ><el-form-item
              label="现家庭住址"
              class="normal_form"
              prop="current_residence"
            >
              <el-input
                v-model="form.current_residence"
                placeholder="请输入现家庭住址"
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="8"
            ><el-form-item
              label="本人联系方式"
              class="normal_form"
              prop="mobile"
            >
              <el-input
                v-model="form.mobile"
                placeholder="请输入手机号码"
              ></el-input> </el-form-item
          ></el-col>
        </el-row>
        <el-row>
          <el-col :span="8"
            ><el-form-item
              label="紧急情况联系人姓名"
              class="normal_form"
              prop="emergency_name"
            >
              <el-input
                v-model="form.emergency_name"
                placeholder="请输入紧急情况联系人姓名"
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="8"
            ><el-form-item
              label="与本人关系"
              class="normal_form"
              prop="emergency_relate"
            >
              <el-input
                v-model="form.emergency_relate"
                placeholder="请输入与本人关系"
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="8"
            ><el-form-item
              label="紧急情况联系人电话"
              class="normal_form"
              prop="emergency_phone"
            >
              <el-input
                v-model="form.emergency_phone"
                placeholder="请输入紧急情况联系人电话"
              ></el-input> </el-form-item
          ></el-col>
        </el-row>
        <el-row>
          <el-col :span="8"
            ><el-form-item label="参加工作时间" class="normal_form">
              <el-date-picker
                v-model="form.work_time"
                type="date"
                placeholder="请选择参加工作时间"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker> </el-form-item
          ></el-col>
          <el-col :span="8"
            ><el-form-item
              label="最早入职时间"
              class="normal_form"
              prop="earliest_entry_time"
            >
              <el-date-picker
                v-model="form.earliest_entry_time"
                type="date"
                placeholder="请选择最早入职时间"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker> </el-form-item
          ></el-col>
          <el-col :span="8"
            ><el-form-item label="入职集团公司时间" class="normal_form">
              <el-date-picker
                v-model="form.join_group_company_time"
                type="date"
                placeholder="请选择入职集团公司时间"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker> </el-form-item
          ></el-col>
        </el-row>
        <el-row>
          <el-col :span="8"
            ><el-form-item
              label="入职劳务公司时间"
              class="normal_form"
              prop="join_service_company_time"
            >
              <el-date-picker
                v-model="form.join_service_company_time"
                type="date"
                placeholder="请选择入职劳务公司时间"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker> </el-form-item
          ></el-col>
          <el-col :span="8"
            ><el-form-item
              label="入职公司时间"
              class="normal_form"
              prop="join_company_time"
            >
              <el-date-picker
                v-model="form.join_company_time"
                type="date"
                placeholder="请选择入职公司时间"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker> </el-form-item
          ></el-col>
          <el-col :span="8"
            ><el-form-item
              label="入职公司服务年限"
              class="normal_form"
              prop="join_service_year"
            >
              <el-input
                v-model="form.join_service_year"
                placeholder="请输入入职公司服务年限"
              ></el-input> </el-form-item
          ></el-col>
        </el-row>
        <el-row>
          <el-col :span="8"
            ><el-form-item
              label="入职介绍人"
              class="normal_form"
              prop="introducer"
            >
              <el-input
                v-model="form.introducer"
                placeholder="请输入入职介绍人"
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="8"
            ><el-form-item label="是否退伍军人" class="normal_form">
              <el-radio-group v-model="form.is_veteran">
                <el-radio :label="1">是</el-radio>
                <el-radio :label="0">否</el-radio>
              </el-radio-group>
            </el-form-item></el-col
          >
          <el-col :span="8"
            ><el-form-item label="备注" class="normal_form">
              <el-input
                v-model="form.remark"
                type="textarea"
                autosize
                placeholder="请输入备注"
              ></el-input> </el-form-item
          ></el-col>
        </el-row>
      </el-form-item>
      <el-form-item label="任职情况" class="bold_form">
        <el-row>
          <el-col :span="8"
            ><el-form-item
              label="任职时间"
              class="normal_form"
              prop="entry_time"
            >
              <el-date-picker
                v-model="form.entry_time"
                type="date"
                placeholder="请输入任职时间"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker> </el-form-item
          ></el-col>
          <el-col :span="8"
            ><el-form-item
              label="考核期限"
              class="normal_form"
              prop="probation_period"
            >
              <el-date-picker
                v-model="form.probation_period"
                type="date"
                placeholder="请选择考核期限"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker> </el-form-item
          ></el-col>
          <el-col :span="8"
            ><el-form-item
              label="考核期满时间"
              class="normal_form"
              prop="probation_expire_time"
            >
              <el-date-picker
                v-model="form.probation_expire_time"
                type="date"
                placeholder="请选择考核期满时间"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker> </el-form-item
          ></el-col>
        </el-row>
        <el-row>
          <el-col :span="8"
            ><el-form-item
              label="任职文号"
              class="normal_form"
              prop="office_sn"
            >
              <el-input
                v-model="form.office_sn"
                placeholder="请输入任职文号"
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="8"
            ><el-form-item
              label="任职经历"
              class="normal_form"
              prop="office_job_exp"
            >
              <el-input
                v-model="form.office_job_exp"
                type="textarea"
                autosize
                placeholder="请输入任职经历"
              ></el-input> </el-form-item
          ></el-col>
        </el-row>
      </el-form-item>
      <el-form-item label="劳动合同情况" class="bold_form">
        <el-row>
          <el-col :span="8"
            ><el-form-item
              label="初始合同起期"
              class="normal_form"
              prop="contract_first_begin_time"
            >
              <el-date-picker
                v-model="form.contract_first_begin_time"
                type="date"
                placeholder="请选择初始合同起期"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker> </el-form-item
          ></el-col>
          <el-col :span="8"
            ><el-form-item
              label="当期合同起期"
              class="normal_form"
              prop="contract_begin_time"
            >
              <el-date-picker
                v-model="form.contract_begin_time"
                type="date"
                placeholder="请选择当期合同起期"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker> </el-form-item
          ></el-col>
          <el-col :span="8"
            ><el-form-item
              label="当期合同终期"
              class="normal_form"
              prop="contract_end_time"
            >
              <el-date-picker
                v-model="form.contract_end_time"
                type="date"
                placeholder="请选择当期合同终期"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker> </el-form-item
          ></el-col>
        </el-row>
        <el-row>
          <el-col :span="8"
            ><el-form-item
              label="当期合同年限"
              class="normal_form"
              prop="contract_service_year"
            >
              <el-input
                v-model="form.contract_service_year"
                placeholder="请选择当期合同年限"
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="8"
            ><el-form-item
              label="试用期满时间"
              class="normal_form"
              prop="period_expire_time"
            >
              <el-date-picker
                v-model="form.period_expire_time"
                type="date"
                placeholder="请选择试用期满时间"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker></el-form-item
          ></el-col>
          <el-col :span="8"
            ><el-form-item
              label="约定岗位"
              class="normal_form"
              prop="per_station_name"
            >
              <el-input
                v-model="form.per_station_name"
                placeholder="请输入约定岗位"
              ></el-input> </el-form-item
          ></el-col>
        </el-row>
        <el-row>
          <el-col :span="8"
            ><el-form-item
              label="基本月薪"
              class="normal_form"
              prop="salary_base"
            >
              <el-input
                v-model="form.salary_base"
                placeholder="请输入基本月薪"
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="8"
            ><el-form-item
              label="出勤标准"
              class="normal_form"
              prop="attendance_standard"
            >
              <el-input
                v-model="form.attendance_standard"
                placeholder="请选择出勤标准"
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="8"
            ><el-form-item
              label="正常工作时间工资"
              class="normal_form"
              prop="normal_work_salary"
            >
              <el-input
                v-model="form.normal_work_salary"
                placeholder="请选择正常工作时间工资"
              ></el-input> </el-form-item
          ></el-col>
        </el-row>
        <el-row>
          <el-col :span="8"
            ><el-form-item
              label="开户行类别"
              class="normal_form"
              prop="bank_deposit"
            >
              <el-input
                v-model="form.bank_deposit"
                placeholder="请输入开户行类别"
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="8"
            ><el-form-item label="银行帐号" class="normal_form" prop="bank_no">
              <el-input
                v-model="form.bank_no"
                placeholder="请输入银行账号"
              ></el-input> </el-form-item
          ></el-col>
        </el-row>
      </el-form-item>
      <el-form-item label="社会保险情况" class="bold_form">
        <el-row>
          <el-col :span="8"
            ><el-form-item label="个人社保编号" class="normal_form">
              <el-input
                v-model="form.social_security"
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="8"
            ><el-form-item label="投保时间" class="normal_form">
              <el-date-picker
                v-model="form.social_time"
                type="date"
                placeholder="请选择投保时间"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker></el-form-item
          ></el-col>
          <el-col :span="8"
            ><el-form-item
              label="投保地点"
              class="normal_form"
              prop="social_place"
            >
              <el-input
                v-model="form.social_place"
                placeholder="请输入投保地点"
              ></el-input></el-form-item
          ></el-col>
        </el-row>
        <el-row>
          <el-col :span="8"
            ><el-form-item label="投保险数" class="normal_form">
              <el-input v-model="form.social_number"></el-input> </el-form-item
          ></el-col>
          <el-col :span="8"
            ><el-form-item label="缴费基数" class="normal_form">
              <el-input v-model="form.social_base"></el-input> </el-form-item
          ></el-col>
          <el-col :span="8"
            ><el-form-item
              label="意外险"
              class="normal_form"
              prop="accident_insurance"
            >
              <el-input
                v-model="form.accident_insurance"
                placeholder="请输入意外险"
              ></el-input> </el-form-item
          ></el-col>
        </el-row>
      </el-form-item>
      <el-form-item label="公积金情况" class="bold_form">
        <el-row>
          <el-col :span="8"
            ><el-form-item
              label="个人账号"
              class="normal_form"
              prop="accumulation_fund"
            >
              <el-input
                v-model="form.accumulation_fund"
                placeholder="请输入个人账号"
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="8"
            ><el-form-item
              label="投保时间"
              class="normal_form"
              prop="fund_time"
            >
              <el-date-picker
                v-model="form.fund_time"
                type="date"
                placeholder="请选择投保时间"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker></el-form-item
          ></el-col>
          <el-col :span="8"
            ><el-form-item
              label="购买地点"
              class="normal_form"
              prop="fund_place"
            >
              <el-input
                v-model="form.fund_place"
                placeholder="请输入购买地点"
              ></el-input> </el-form-item
          ></el-col>
        </el-row>
        <el-row>
          <el-col :span="8"
            ><el-form-item label="投保基数" class="normal_form">
              <el-input v-model="form.fund_base"></el-input> </el-form-item
          ></el-col>
          <el-col :span="8"
            ><el-form-item
              label="投保比例"
              class="normal_form"
              prop="insurance_rate"
            >
              <el-input
                v-model="form.insurance_rate"
                placeholder="请输入投保比例"
              ></el-input> </el-form-item
          ></el-col>
        </el-row>
      </el-form-item>
      <el-form-item label="发薪相关" class="bold_form">
        <el-row>
          <el-col :span="8"
            ><el-form-item
              label="发薪公司"
              class="normal_form"
              prop="payroll_company"
            >
              <el-input
                v-model="form.payroll_company"
                placeholder="请输入发薪公司"
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="8"
            ><el-form-item
              label="公司名称"
              class="normal_form"
              prop="company_name"
            >
              <el-input
                v-model="form.company_name"
                placeholder="请输入公司名称"
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="8"
            ><el-form-item
              label="加点替岗1工资标准"
              class="normal_form"
              prop="add_replace_wages_standard_first"
            >
              <el-input
                v-model="form.add_replace_wages_standard_first"
                placeholder="请输入加点替岗1工资标准"
              ></el-input> </el-form-item
          ></el-col>
        </el-row>
        <el-row>
          <el-col :span="8"
            ><el-form-item
              label="加点替岗2工资标准"
              class="normal_form"
              prop="add_replace_wages_standard_second"
            >
              <el-input
                v-model="form.add_replace_wages_standard_second"
                placeholder="请输入加点替岗2工资标准"
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="8"
            ><el-form-item
              label="加点替岗3工资标准"
              class="normal_form"
              prop="add_replace_wages_standard_third"
            >
              <el-input
                v-model="form.add_replace_wages_standard_third"
                placeholder="请输入加点替岗3工资标准"
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="8"
            ><el-form-item label="加点替岗类别" class="normal_form">
              <el-radio-group v-model="form.add_replace_wages_standard_type">
                <el-radio :label="1">1</el-radio>
                <el-radio :label="2">2</el-radio>
                <el-radio :label="3">3</el-radio>
              </el-radio-group></el-form-item
            ></el-col
          >
        </el-row>
        <el-row>
          <el-col :span="8"
            ><el-form-item
              label="个税专项扣除额"
              class="normal_form"
              prop="deduct_tax_amount"
            >
              <el-input
                v-model="form.deduct_tax_amount"
                placeholder="请输入个税专项扣除额"
              ></el-input> </el-form-item
          ></el-col>
        </el-row>
      </el-form-item>
      <!-- <el-form-item label="员工人脸" prop="image">
        <el-image
          v-if="form.punch_face"
          :src="host + form.punch_face"
          style="width: 100px; height: 100px"
          fit="cover"
          @click="chooseImage"
          @error="form.punch_face = ''"
        ></el-image>
        <div v-else class="add_photo" @click="chooseImage">
          <i class="el-icon-plus"></i>
        </div>
        <input
          ref="fileInput"
          type="file"
          accept="image/*"
          @change="uploadImage"
          @click="
            (event) => {
              event.target.value = null;
            }
          "
          style="width: 0; height: 0; position: absolute"
        />
      </el-form-item> -->

      <!-- <el-divider></el-divider> -->

      <el-form-item class="form_btns" v-if="$route.params.isSave">
        <div class="form_btns">
          <div class="custom_button no_select" @click="onSubmit">保存</div>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import AreaJson from "@/util/area-full.json";
import City from "@/minix/getCity";
export default {
  data() {
    return {
      host: process.env.VUE_APP_BASE_API,
      areaData: null,
      residence_type_options: [],
      cert_type_options: [],
      form: {
        nation: "", //民族
        face: "", //政治面貌
        // punch_face: "",
        cert_type: "",
        sex: "", //性别
        birthday: "", //生日
        is_marry: 0, //婚否

        address: "", //户口地址
        residence_type: "", //户口性质
        current_residence: "", //现在家庭住址
        bank_no: "", //银行帐号
        bank_deposit: "", //开户行类别
        emergency_name: "", //紧急情况联系人姓名
        emergency_relate: "", //与本人关系
        // emergency_address: "",
        emergency_phone: "", //紧急情况联系人电话

        contract_sn: "", //合同编号
        department_id: [], //所在单位
        duty_id: [], //所在项目
        is_social_security: 1, //用工类别 A或者B
        work_type: "", //工种
        post_level: "", //岗位层级
        station_id: [], //岗位名称
        job_number: "", //员工编号
        real_name: "", //员工姓名
        age: "", //年龄
        cert_number: "", //身份证号码
        certifity_expired: "", //身份证有效止期
        edu: "", //文化程度
        graduation_time: "", //毕业时间
        graduation_college: "", //毕业学校
        graduation_major: "", //毕业专业
        edu_prove: "", //学历证书编号
        //省市区id
        province: "",
        city: "",
        area: "",
        //家庭所在省市区id
        current_province: "",
        current_city: "",
        current_area: "",
        mobile: "", //本人联系方式
        work_time: "", //参加工作时间
        earliest_entry_time: "", //最早入职时间
        join_group_company_time: "", //入职集团公司时间
        join_service_company_time: "", //入职劳务公司时间
        join_company_time: "", //入职公司时间
        join_service_year: "", //入职公司服务年限
        introducer: "", //入职介绍人
        is_veteran: 0, //是否退伍军人
        remark: "", //备注
        entry_time: "", //任职时间
        probation_period: "", //考核期限
        probation_expire_time: "", //考核期满时间
        office_sn: "", //任职文号
        office_job_exp: "", //任职经历
        contract_first_begin_time: "", //初始合同起期
        contract_begin_time: "", //当期合同起期
        contract_end_time: "", //当期合同终期
        contract_service_year: "", //当期合同年限
        period_expire_time: "", //试用期满时间
        per_station_name: "", //约定岗位
        salary_base: "", //基本月薪
        attendance_standard: "", //出勤标准
        normal_work_salary: "", //正常工作时间工资
        social_security: "", //个人社保编号
        social_time: "", //投保时间
        social_place: "", //投保地点
        social_number: "", //投保险数
        social_base: "", //缴费基数
        accident_insurance: "", //意外险
        accumulation_fund: "", //个人账号
        fund_time: "", //投保时间
        fund_place: "", //购买地点
        fund_base: "", //投保基数
        insurance_rate: "", //投保比例
        payroll_company: "", //发薪公司
        company_name: "", //公司名称
        add_replace_wages_standard_first: "", //加点替岗1工资标准
        add_replace_wages_standard_second: "", //加点替岗2工资标准
        add_replace_wages_standard_third: "", //加点替岗3工资标准
        add_replace_wages_standard_type: 1, //加点替岗类别
        deduct_tax_amount: "", //个税专项扣除额

        dataModel: [], //户口所在地省市区
        currentData: [], //现家庭所在地省市区
      },
      rules: {
        department_id: [{ required: true, message: "请选择所在单位" }],
        duty_id: [{ required: true, message: "请选择所在项目" }],
        station_id: [{ required: true, message: "请选择岗位名称" }],
        post_level: [{ required: true, message: "请输入岗位层级" }],
        real_name: [{ required: true, message: "请输入姓名" }],
        nation: [{ required: true, message: "请输入民族" }],
        birthday: [{ required: true, message: "请选择出生年月" }],
        age: [{ required: true, message: "请选择年龄" }],
        cert_number: [{ required: true, message: "请输入身份证号码" }],
        certifity_expired: [
          { required: true, message: "请选择身份证有效止期" },
        ],
        face: [{ required: true, message: "请输入政治面貌" }],
        work_type: [{ required: true, message: "请输入工种" }],
        edu: [{ required: true, message: "请输入文化程度" }],
        graduation_time: [{ required: true, message: "请选择毕业时间" }],
        graduation_college: [{ required: true, message: "请输入毕业学校" }],
        graduation_major: [{ required: true, message: "请输入毕业专业" }],
        edu_prove: [{ required: true, message: "请输入学历证书编号" }],
        dataModel: [{ required: true, message: "请选择户口所在地省市区" }],
        residence_type: [{ required: true, message: "请选择户口性质" }],
        address: [{ required: true, message: "请选择户口地址" }],
        currentData: [{ required: true, message: "请选择现家庭所在地省市区" }],
        current_residence: [
          { required: true, message: "请选择现家庭所在地省市区" },
        ],
        mobile: [
          { required: true, message: "请输入手机号码" },
          {
            required: true,
            pattern: /^1[3-9]\d{9}$/,
            message: "手机号码有误",
          },
        ],
        emergency_phone: [
          { required: true, message: "请输入手机号码" },
          {
            required: true,
            pattern: /^1[3-9]\d{9}$/,
            message: "手机号码有误",
          },
        ],
        emergency_name: [
          { required: true, message: "请输入紧急情况联系人姓名" },
        ],
        emergency_relate: [{ required: true, message: "请输入与本人关系" }],
        earliest_entry_time: [
          { required: true, message: "请选择最早入职时间" },
        ],
        join_service_company_time: [
          { required: true, message: "请选择入职劳务公司时间" },
        ],
        join_company_time: [{ required: true, message: "请选择入职公司时间" }],
        join_service_year: [
          { required: true, message: "请输入入职公司服务年限" },
        ],
        introducer: [{ required: true, message: "请输入入职介绍人" }],
        // entry_time: [{ required: true, message: "请输入任职时间" }],
        // probation_period: [{ required: true, message: "请选择考核期限" }],
        // probation_expire_time: [
        //   { required: true, message: "请选择考核期满时间" },
        // ],
        // office_sn: [{ required: true, message: "请输入任职文号" }],
        // office_job_exp: [{ required: true, message: "请输入任职经历" }],
        contract_first_begin_time: [
          { required: true, message: "请选择初始合同起期" },
        ],
        contract_begin_time: [
          { required: true, message: "请选择初始合同起期" },
        ],
        contract_end_time: [{ required: true, message: "请选择当期合同终期" }],
        contract_service_year: [
          { required: true, message: "请选择当期合同年限" },
        ],
        period_expire_time: [{ required: true, message: "请选择试用期满时间" }],
        per_station_name: [{ required: true, message: "请输入约定岗位" }],
        salary_base: [{ required: true, message: "请输入基本月薪" }],
        attendance_standard: [{ required: true, message: "请选择出勤标准" }],
        normal_work_salary: [
          { required: true, message: "请选择正常工作时间工资" },
        ],
        bank_deposit: [{ required: true, message: "请输入开户行类别" }],
        bank_no: [{ required: true, message: "请输入银行账号" }],
        social_place: [{ required: true, message: "请输入投保地点" }],
        accident_insurance: [{ required: true, message: "请输入意外险" }],
        accumulation_fund: [{ required: true, message: "请输入个人账号" }],
        fund_time: [{ required: true, message: "请选择投保时间" }],
        fund_place: [{ required: true, message: "请输入购买地点" }],
        insurance_rate: [{ required: true, message: "请输入购买地点" }],
        payroll_company: [{ required: true, message: "请输入发薪公司" }],
        company_name: [{ required: true, message: "请输入公司名称" }],
        add_replace_wages_standard_first: [
          { required: true, message: "请输入加点替岗1工资标准" },
        ],
        add_replace_wages_standard_second: [
          { required: true, message: "请输入加点替岗2工资标准" },
        ],
        add_replace_wages_standard_third: [
          { required: true, message: "请输入加点替岗3工资标准" },
        ],
        deduct_tax_amount: [
          { required: true, message: "请输入个税专项扣除额" },
        ],
      },

      department: [], //单位列表
      projectList: [], //项目列表
      stationList: [], //岗位列表
      cityList: [], //城市列表
    };
  },
  props: ["id"],
  created() {
    // 单位列表
    this.getDepartmentList().then((res) => {
      if (res.data.code == 200) {
        this.filterDepart(res.data.data.list.data, "1");
      }
    });
    //项目列表
    this.getProjectList().then((res) => {
      // console.log("所在单位", res);
      if (res.data.code == 200) {
        this.filterDepart(res.data.data.list.data, "2");
      }
    });
    // 岗位列表
    this.getStationList().then((res) => {
      console.log("所在单位", res);
      if (res.data.code == 200) {
        console.log(res.data.data.list, "岗位列表");
        this.filterDepart(res.data.data.list, "3");
      }
    });

    this.areaData = AreaJson;
    for (var i = 0; i < this.areaData.length; i++) {
      if (this.areaData[i].children.length == 0) {
        delete this.areaData[i].children; //解决因为省级区域没有下级市的BUG
      }
    }

    this.getResidenceTypeCertTypeData()
      .then((res) => {
        console.log("getResidenceTypeCertTypeData", res);
        this.residence_type_options = res.data.data.residence_type.map(
          (item, index) => {
            return {
              id: index,
              name: item,
            };
          }
        );
        this.cert_type_options = res.data.data.cert_type.map((item, index) => {
          return {
            id: index,
            name: item,
          };
        });
      })
      .catch((e) => {
        console.error(e);
      });

    let loading = this.$loading();
    this.getEmployeeDetail({ id: this.id })
      .then((res) => {
        console.log(res, "详情");
        loading.close();
        let {
          province,
          city,
          area,
          current_province,
          current_city,
          current_area,
        } = res.data.data.detail;
        this.form = {
          ...JSON.parse(JSON.stringify(this.form)),
          ...res.data.data.detail,
          dataModel: [province, city, area],
          currentData: [current_province, current_city, current_area],
        };
      })
      .catch((e) => {
        console.log(e);
      });
    console.log(this.$route.params.isSave);
    //修改才调用
    this.cityList = City;
    // if (this.$route.params.isSave) {
    //   //获取城市列表
    //   this.getAllCity().then((res) => {
    //     console.log("城市", res);
    //     if (res.data.code == 200) {
    //       this.cityList = res.data.data;
    //     }
    //   });
    // }
  },
  beforeDestroy() {
    // this.$loading().close();
  },
  methods: {
    ...mapActions(["uploadFile"]),
    ...mapActions("employee", [
      "getEmployeeDetail",
      "postEmployeeDetail",
      "getResidenceTypeCertTypeData",
      "getDepartmentList",
      "getProjectList",
      "getStationList",
      "getAllCity",
      "passEmployeeEntry",
    ]),

    filterDepart(list, status) {
      console.log(list, "list");
      list.length > 0 &&
        list.map((item) => {
          item.value = item.id;
          item.label = item.name;
          item.child &&
            item.child.length > 0 &&
            item.child.map((e) => {
              e.value = e.id;
              e.label = e.name;
            });
          if (item.child && item.child.length > 0) {
            item.children = item.child;
          }
        });

      if (status == 1) {
        this.department = list;
      }
      if (status == 2) {
        this.projectList = list;
      }
      if (status == 3) {
        this.stationList = list;
      }
    },

    /**
     * 选择图片
     */
    chooseImage() {
      this.$refs.fileInput.click();
    },

    /**
     * 上传图片
     */
    uploadImage(file) {
      console.log(file);
      this.uploadFile(file.target.files[0])
        .then((res) => {
          console.log(res);
          this.form.punch_face = res.data.data.full_link;
        })
        .catch((err) => {
          console.error(err);
          this.$message.error(err.msg);
        });
    },

    /**
     * 提交表单 入职
     */
    onSubmit() {
      console.log(this.$route.params.id);
      let id = this.$route.params.id;
      let form = this.form;
      let params = {};
      for (const key in form) {
        if (form[key] instanceof Array) {
          console.log(key, form[key]);
          let keyList = ["department_id", "duty_id", "station_id"];
          if (keyList.indexOf(key) != -1 && form[key].length > 0) {
            params[key] = form[key][form[key].length - 1];
          }
          if (key == "dataModel" && form[key].length > 0) {
            form.province = form[key][0] ?? "";
            form.city = form[key][1] ?? "";
            form.area = form[key][2] ?? "";
          }
          if (key == "currentData" && form[key].length > 0) {
            form.current_province = form[key][0] ?? "";
            form.current_city = form[key][1] ?? "";
            form.current_area = form[key][2] ?? "";
          }
        } else {
          params[key] = form[key];
        }
      }
      params.id = id;
      console.log(params);
      this.$refs.form.validate((valid, object) => {
        if (valid) {
          console.log("通过校验", this.$route);
          let loading = this.$loading();
          this.passEmployeeEntry(params)
            .then((res) => {
              console.log(res);
              loading.close();
              this.$message.success(res.data.msg);
            })
            .catch((e) => {
              console.error(e);
              loading.close();
              this.$message.error(e.msg);
            });
        } else {
          console.log("无法通过校验", object);
          this.$nextTick(() => {
            let isError = document.getElementsByClassName("is-error");
            isError[0].scrollIntoView({
              // 滚动到指定节点
              // 值有start,center,end，nearest，当前显示在视图区域中间
              block: "center",
              // 值有auto、instant,smooth，缓动动画（当前是慢速的）
              behavior: "smooth",
            });
            return;
          });
        }
      });
      // try {
      //   let province = this.areaData.find(
      //     (item) => item.value == this.form.dataModel[0]
      //   );
      //   let city = province.children.find(
      //     (item) => item.value == this.form.dataModel[1]
      //   );
      //   let area = city.children.find(
      //     (item) => item.value == this.form.dataModel[2]
      //   );
      //   this.form.province = province.label;
      //   this.form.city = city.label;
      //   this.form.area = area.label;
      // } catch (e) {
      //   console.log("未填写地址", e);
      // }
      // this.$refs.form.validate((valid) => {
      //   if (valid) {
      //     let loading = this.$loading();
      //     console.log(this.form);
      //     this.postEmployeeDetail(this.form)
      //       .then((res) => {
      //         console.log(res);
      //         loading.close();
      //         this.$message.success(res.data.msg);
      //       })
      //       .catch((e) => {
      //         console.error(e);
      //         loading.close();
      //         this.$message.error(e.msg);
      //       });
      //   } else {
      //     this.$notify.error({
      //       title: "提交失败",
      //       message: "请先完整填写表单",
      //     });
      //     return false;
      //   }
      // });
    },
  },
};
</script>

<style lang="scss" scoped>
.bold_form {
  font-weight: bolder;
  font-size: 18px;
  ::v-deep > .el-form-item__label {
    font-size: 18px;
  }
  .normal_form {
    ::v-deep > .el-form-item__label {
      // font-size: 18px;
      font-weight: normal;
    }

    // ::v-deep > .el-form-item__label {
    //   float: left;
    //   margin-right: 10px;
    // }
    // ::v-deep > .el-form-item__content {
    //   float: left;
    // }
  }
}
.title {
  font-size: 14px;
  font-weight: bold;
  color: #1c2438;
}

.add_photo {
  width: 100px;
  height: 100px;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #dcdfe6;
  border-radius: 3px;
  color: #dcdfe6;
}

::v-deep .el-select,
.el-cascader {
  width: 400px;
}

// ::v-deep .el-input__inner {
//   width: 480px;
// }

.el-input {
  width: 400px;
  height: 48px;
  font-size: 14px;
  color: #222222;
}

.form_btns {
  display: flex;
  align-items: center;

  .custom_button {
    width: 142px;
    height: 48px;
    line-height: 48px;
    font-size: 14px;
  }

  > div:not(:first-child) {
    margin-left: 20px;
  }
}
::v-deep .el-row {
  margin-bottom: 20px;
}
</style>
